import React from 'react'
import Layout from '../layouts'

const Subscription = () => (
	<Layout head={{ title: 'Subscription' }}>
		<h1>Subscription Page</h1>
	</Layout>
)

export default Subscription
